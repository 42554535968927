import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BRAND_WEBBANKIR } from '../../../constants/brand-webbankir.const';
import { IConsentDto } from '../../../interfaces/consent-dto.interface';
import { IRegistrationUser } from '@app/states/registration/interfaces/registration-user.interface';
import { IAccount } from '@app/interfaces/account.interface';
import { IAccountCreateDto } from '@app/interfaces/account-create-dto.interface';
import { IDataResponse } from '@app/interfaces/data-response.interface';
import { IPhoneCode } from '@app/states/registration/interfaces/phone-code.interface';
import { IPhoneCodeDto } from '@app/states/registration/interfaces/phone-code-dto.interface';
import { IFingerPrint } from '@app/interfaces/finger-print.interface';
import { ConsentPackage } from '@app/constants/consent-package.const';
import { ILoanDto } from '@app/states/registration/interfaces/loan-dto.interface';
import { ILoan } from '@app/interfaces/loan/loan.interface';
import {
  IRegistrationApplicationDto,
} from '@app/states/registration/interfaces/registration-application-dto.interface';
import { IVerifyDto } from '@app/states/registration/interfaces/verify-dto.interface';
import { ICalculator } from '@app/interfaces/calculator.interface';
import { IFillAdditional } from '@app/states/registration/interfaces/fill-additional.interface';
import { IJsonRpcResponse } from '@app/interfaces/jsonrpc-response.interface';
import { v4 as uuid } from 'uuid';
import { ILoginResponse } from '@app/interfaces/login-response.interface';
import { IMobileIDDataDto } from '@app/states/registration/interfaces/mobile-id-data-dto.interface';
import { IMobileIDData } from '@app/states/registration/interfaces/mobile-id-data.interface';
import {
  IAddressSuggestionRequestDto,
  IEmailSuggestionRequestDto,
  IFioSuggestionRequestDto,
  IFmsSuggestionRequestDto,
} from '../interfaces/suggestion-request-dto.interface';
import { IJsonRpcRequest } from '@app/interfaces/jsonrpc-request.interface';
import {
  IAddressSuggestionResponseDto,
  IEmailSuggestionResponseDto,
  IFioSuggestionResponseDto,
  IFmsSuggestionResponseDto,
} from '../interfaces/suggestion-response-dto.interface';
import { ICleanerName } from '@app/states/registration/interfaces/cleaner-name.interface';
import { IRegistrationIdentification } from '../interfaces/registration-identification.interface';
import { IIdentificationResponse } from '../interfaces/identification-response.interface';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private readonly userPath: string;

  private readonly accountPath: string;
  private readonly loanPath: string;
  private readonly rpcUrl: string;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
  ) {
    this.userPath = `${this.environment.api}user`;
    this.accountPath = `${this.environment.api}accounts`;
    this.loanPath = `${this.environment.api}loan`;
    this.rpcUrl = `${this.environment.gate}rpc`;
  }

  public loanCalculator(): Observable<IDataResponse<ICalculator>> {
    return this.http.get<IDataResponse<ICalculator>>(`${this.environment.api}loan-calculator/v3`);
  }

  public consents(type: ConsentPackage, attributes: IFingerPrint): Observable<IDataResponse<IConsentDto[]>> {
    return this.http.post<IDataResponse<IConsentDto[]>>(
      `${this.userPath}/v2/consents/packages/${type}`,
      {
        data: {
          type: 'ConsentsRequest',
          attributes: {
            brand: BRAND_WEBBANKIR,
            ...attributes,
          },
        },
      },
    );
  }

  public esiaGet(key: string): Observable<IRegistrationApplicationDto> {
    return this.http.post<IRegistrationApplicationDto>(`${this.userPath}/esia/get`, { key });
  }

  public submit(data: Partial<IRegistrationApplicationDto>): Observable<IDataResponse<IRegistrationUser>> {
    return this.http.post<IDataResponse<IRegistrationUser>>(`${this.userPath}/v2/create`, data);
  }

  public submitThreeStep(data: Partial<IRegistrationApplicationDto>): Observable<HttpResponse<IDataResponse<IRegistrationUser>>> {
  return this.http.post<IDataResponse<IRegistrationUser>>(`${this.userPath}/v2/create`, data, {observe: 'response'} );
}

  public verify(data: Partial<IVerifyDto>): Observable<void> {
    return this.http.post<void>(`${this.userPath}/verify`, data);
  }

  private passport(term: string): Observable<IJsonRpcResponse<IFmsSuggestionResponseDto[]>> {
    const requestParams: IFmsSuggestionRequestDto = {
      count: 20,
      division_code: term,
    };

    const request = this.createRpcRequest('suggest_fms_unit', requestParams);
    return this.http.post<IJsonRpcResponse<IFmsSuggestionResponseDto[]>>(this.rpcUrl, request);
  }

  private address(term: string): Observable<IJsonRpcResponse<IAddressSuggestionResponseDto[]>> {
    const requestParams: IAddressSuggestionRequestDto = {
      count: 5,
      address: term,
    };

    const request = this.createRpcRequest('suggest_address', requestParams);

    return this.http.post<IJsonRpcResponse<IAddressSuggestionResponseDto[]>>(this.rpcUrl, request);
  }

  public suggestion(type: string, val: string): Observable<IJsonRpcResponse<
    IAddressSuggestionResponseDto[] |
    IFmsSuggestionResponseDto[] |
    IEmailSuggestionResponseDto[] |
    IFioSuggestionResponseDto[]
  >> {
    switch (type) {
      case 'address':
        return this.address(val);
      case 'passport':
        return this.passport(val);
      case 'email':
        return this.email(val);
      case 'fio':
        return this.fio(val);
    }
  }

  public identification(data: IRegistrationIdentification): Observable<void> {
    return this.http.post<void>(`${this.environment.api}identification/v1/applications`, {data})
  }
  
  public getInn(clientId: string): Observable<IDataResponse<IIdentificationResponse[]>> {
    return this.http.get<IDataResponse<IIdentificationResponse[]>>(`${this.environment.api}identification/v1/applications?filter[cross_id]=${clientId}&filter[state]=simplified&fields[applications]=inn&limit=1`)
  }

  private email(term: string): Observable<IJsonRpcResponse<IEmailSuggestionResponseDto[]>> {
    const requestParams: IEmailSuggestionRequestDto = {
      count: 5,
      email: term,
    };

    const request = this.createRpcRequest('suggest_email', requestParams);

    return this.http.post<IJsonRpcResponse<IEmailSuggestionResponseDto[]>>(this.rpcUrl, request);
  }

  private fio(term: string): Observable<IJsonRpcResponse<IFioSuggestionResponseDto[]>> {
    const requestParams: IFioSuggestionRequestDto = {
      count: 5,
      fio: term,
    };

    const request = this.createRpcRequest('suggest_fio', requestParams);

    return this.http.post<IJsonRpcResponse<IFioSuggestionResponseDto[]>>(this.rpcUrl, request);
  }

  public buildFio(fio: string): Observable<IJsonRpcResponse<ICleanerName>> {

    const requestParams: IFioSuggestionRequestDto = {
      fio,
    };

    const request = this.createRpcRequest('cleaner_name', requestParams);

    return this.http.post<IJsonRpcResponse<ICleanerName>>(this.rpcUrl, request);
  }

  public code(data: IPhoneCode): Observable<IDataResponse<IPhoneCodeDto>> {
    return this.http.post<IDataResponse<IPhoneCodeDto>>(`${this.userPath}/v2/phone_verification`, { data });
  }

  public verifyCode(verificationId: string, data: IPhoneCode): Observable<IDataResponse<IPhoneCodeDto>> {
    return this.http.patch<IDataResponse<IPhoneCodeDto>>(`${this.userPath}/v2/phone_verification/${verificationId}`, { data });
  }

  public mobileId(data: IMobileIDData): Observable<IDataResponse<{ attributes: IMobileIDDataDto }>> { // TODO
    return this.http.post<IDataResponse<{ attributes: IMobileIDDataDto }>>(`${this.userPath}/v2/personal_data`, { data });
  }

  public accounts(): Observable<IDataResponse<IAccount[]>> {
    return this.http.get<IDataResponse<IAccount[]>>(`${this.accountPath}`);
  }

  public fillAdditional(data: IFillAdditional): Observable<void> {
    return this.http.patch<void>(`${this.userPath}/v2/create/fill_additional`, data);
  }

  public createAccount(data: Partial<IAccountCreateDto>): Observable<IDataResponse<IAccount[]>> {
    return this.http.put<IDataResponse<IAccount[]>>(`${this.accountPath}`, data);
  }

  public loanCreate(data: ILoanDto): Observable<HttpResponse<IDataResponse<ILoan[]>>> {
    return this.http.post<IDataResponse<ILoan[]>>(`${this.loanPath}/v2/create`, data, {observe: 'response'});
  }

  public pdl(): Observable<IDataResponse<ILoan>> {
    return this.http.get<IDataResponse<ILoan>>(`${this.loanPath}/get/current/pdl`);
  }

  public esiaData(key: string) {
    return this.http.post<IDataResponse<ILoginResponse> | IRegistrationApplicationDto>(`${this.environment.api}user/esia/get`, { key });
  }

  private createRpcRequest<T>(method: string, params: T): IJsonRpcRequest<T> {
    return {
      id: uuid(),
      jsonrpc: '2.0',
      method,
      params,
    };
  }

  public sendPhone(phone: string, fio: string, step: number): Observable<IJsonRpcResponse<{}>> {

    const requestParams: any = {
      mobile_phone: phone,
      name: fio,
      step 
    };

    const request = this.createRpcRequest('user_registration_phone_call', requestParams);

    return this.http.post<IJsonRpcResponse<{}>>(this.rpcUrl, request);
  }
}
